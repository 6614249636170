<template>
    <a href="javascript:0;"
        data-toggle="popover"
        :title="getTitleText"
        data-trigger="hover"
        data-placement="top"
        data-html="true"
        :data-content="gethelpText">
        <i class="fas fa-info-circle"></i>
    </a>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'cost-help-thirtytage',
    	props: {

    	},
    	mounted: function() {
    		$('[data-toggle="tooltip"]').tooltip();
    		$('[data-toggle="popover"]').popover();
    	},
    	computed: {
    		getTitleText: function() {
    			var titleText = 'Zusätzliche Informationen';

    			return titleText;
    		},
    		gethelpText: function() {
    			//console.log(this.pricelist);
    			var helpText = 'Bei einer Stornierung bis zu 30 Tagen vor Anreise erstatten wir Ihnen den vollen Buchungspreis abzüglich der Gebühr für diese Leistung. Diese Leistung ist nur mit der Buchung und nicht nachträglich buchbar.';
    			return helpText;
    		}
    	}
    };
</script>